import React from "react";
import CookieConsent from "react-cookie-consent";


const Cookie = () => {
  return (
    <CookieConsent
      enableDeclineButton={false}
      flipButtons
      acceptOnScroll={false}
      acceptOnScrollPercentage={50}
      location="bottom"
      buttonText="Accepter les cookies"
      style={{ background: "#efefef", color:"#333" ,height: "90%", testAlign: "center" }}
      buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
      
      expires={150}
    >
      <h3>Acceptation des cookies requise</h3>
        <p>Afin d'optimiser ses performances et ses fonctionnalités, le site chiffres-romain.com emploie le marqueur d'audience "Google Analytics".
        <br/> Pour continuer votre visite vous devez donner explicitement votre accord en cliquant sur le boutton accepter les cookies.</p>
      
    </CookieConsent>
  );
};

export default Cookie;
