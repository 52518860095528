import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Button } from "react-bootstrap";
import { Nav } from "react-bootstrap"; // NavDropdown
import Jumbotron from 'react-bootstrap/Jumbotron';

const Footer = () => {
  return (

    <Container>
      <Jumbotron style={{marginTop:"20px", paddingTop:"0px"}}><div
      className="ml-auto fixed-bottom"
      style={{ color: "#888", fontSize: "12px", backgroundColor: "#111111", textAlign: "center" }}
    ><br />
    <a href="//shulugoo.net/4/6461561" target="_blank" rel="noopener noreferrer">
     <Button variant="warning" style={{ width: "50%" , fontWeight:"bolder"}}>     
LET'S PLAY    
    </Button>
    </a>
    <br /><br />
      
      <Nav.Link
            title="Histoire Fascinante des Chiffres Romains"
            to="/histoire-des-chiffres-romain.html"
            href="histoire-des-chiffres-romain.html"
            style={{display:"inline",color: "#aaaaaa" }}
          >
            Histoire Fascinante des Chiffres Romains
          </Nav.Link>
          <Nav.Link
            title="Du charme antique à la précision moderne"
            to="/du-charme-antique-a-la-précision-moderne.html"
            href="/du-charme-antique-a-la-précision-moderne.html"
            style={{display:"inline", color: "#aaaaaa" }}
          >
            Du charme antique à la précision moderne
          </Nav.Link>
          <Nav.Link
            title="Convertir de chiffres"
            to="/"
            href="/"
            style={{display:"inline", color: "#aaaaaa" }}
          >
            Convertir les chiffres romain
          </Nav.Link>
      <b>©2023 chiffres-romain.com</b> &nbsp;&nbsp;&nbsp;     
    </div></Jumbotron>
    </Container>
  );
};

export default Footer;
