import React, { Component } from "react";
import { Col, Container, Row, Card } from "react-bootstrap";
import Jumbotron from 'react-bootstrap/Jumbotron';
import BG from "./bg.jpeg";
import { Helmet } from "react-helmet";
import { Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Button from "react-bootstrap/Button";


class About extends Component {
  constructor(props) {
    super(props);
    this.state = { isToggleOn: false };

  }
  render() {
    return (
      <div><Jumbotron
        style={{
          color: "#fff",
          fontFamily: "arial",
          backgroundImage: `url(${BG}), linear-gradient(45deg,#aaa 39.9%,#000 60%)`,
          filter: "grayscale(80%)",
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundBlendMode: "multiply",
          backgroundRepeat: 'no-repeat',
          marginBottom: "0px"
        }} fluid>

        <Helmet>
          <meta charSet="utf-8" />
          <title>chiffre-romain.com - convertir des chiffres romain en chiffre arabe standard</title>
          <meta name="description" content="Comment convertir des chiffres standards en chiffre romain" />
        </Helmet>
        <Container >
          <Row style={{ paddingTop: "20px" }}>
            <Col xs lg="12">
              <br /><h1
                style={{
                  paddingLeft: "10px",
                  letterSpacing: "5px",
                  color: "#efefef",
                  fontWeight: "bolder",
                  borderLeft: "10px solid #555555"
                }}
              >Convertisseur de Chiffres Romains en Chiffres Standards Arabes et Vice Versa</h1>
              <Row style={{ textAlign: 'justify', padding: '10px' }}>
                Nos convertisseurs en ligne simples et conviviaux vous permettent de convertir rapidement et avec précision les chiffres romains en chiffres standard et vice versa, des chiffres standards en chiffre Romain.
                <br /> <br /> Que vous ayez besoin de traduire un numéro romain antique en nombre moderne Arabe, ou un nombre moderne en chiffres Romain notre outil vous offre une solution pratique et fiable.
                <br /><br /><hr />

                <Col lg={12}>
                  <p style={{ textAlign: "center" }}>Effectuez vos conversions de chiffres romains en chiffres standards et
                    inversement avec nos convertisseurs en ligne gratuit. </p>
                  <LinkContainer
                    to="/convertisseur-chiffre-moderne-vers-chiffres-Romain-en-ligne.html"
                    style={{ textAlign: "center" }}
                  >
                    <Nav.Link to="/convertisseur-chiffre-moderne-vers-chiffres-Romain-en-ligne.html">
                      <Button variant="success" style={{ width: "80%" }}>
                        Convertir des chiffres modernes en chiffres Romain [<i style={{fontSize:"10px"}}> 15 ={">"} XV </i>]
                      </Button>
                    </Nav.Link>
                  </LinkContainer>
                </Col>
                <Col lg={12} >
                  <LinkContainer
                    to="/convertisseur-chiffres-Romain-vers-chiffre-moderne-en-ligne.html"
                    style={{ textAlign: "center" }}
                  >
                    <Nav.Link to="/convertisseur-chiffres-Romain-vers-chiffre-moderne-en-ligne.html">
                      <Button variant="success" style={{ width: "80%" }}>
                        Convertir des chiffres Romain en chiffres modernes [<i style={{fontSize:"10px"}}> XV ={">"} 15 </i>]
                      </Button>
                    </Nav.Link>
                  </LinkContainer>
                </Col>
              </Row>
            </Col>
          </Row>


        </Container>
      </Jumbotron>

        <Jumbotron style={{ backgroundColor: '#efefef', marginBottom: "0" }} fluid>
          <Container>
          <Row> 
            <h3
              style={{
                paddingLeft: "10px",
                letterSpacing: "3px",
                textTransform: "",
                color: "#555555",
                borderBottom: "1px #555555 solid",
                fontWeight: "bolder",
                borderLeft: "10px solid #555555",
                textAlign: "left"
              }}
            >Introduction aux Chiffres Romains et leur conversion en chiffres modernes</h3>
             <br/><p>Les chiffres romains sont un système de numération ancien qui a été largement utilisé pendant l'Empire romain et continue d'être utilisé aujourd'hui dans divers contextes. Ils sont composés de sept symboles de base : <b>I, V, X, L, C, D et M</b>, qui représentent respectivement les valeurs 1, 5, 10, 50, 100, 500 et 1000.</p>
              <div>
                <h2 style={{textDecoration:"underline"}}>Règles de Conversion chiffres Romain</h2><br />
              <h4>1. Addition et Soustraction</h4>
              <p>L'addition est simple : les symboles romains sont simplement ajoutés ensemble pour obtenir la valeur totale. Par exemple, III représente 1 + 1 + 1 = 3.
              </p>
              <p>La soustraction est également utilisée dans les chiffres romains. Lorsqu'un symbole de valeur inférieure précède un symbole de valeur supérieure, les deux sont combinés pour former une valeur soustraite. Par exemple, IV représente 5 - 1 = 4.</p>
              <h4>2. Utilisation des Symboles Spéciaux</h4>
              <p>Certains symboles spéciaux sont utilisés pour représenter des valeurs telles que 4, 9, 40, 90, 400 et 900. Par exemple, IV représente 4, IX représente 9, XL représente 40, XC représente 90, CD représente 400 et CM représente 900.</p>
              <h4>3. Conversion en Chiffres Modernes</h4>
              <p>Pour convertir des chiffres romains en chiffres modernes, chaque symbole romain est associé à une valeur numérique. En ajoutant ou en soustrayant ces valeurs en fonction des règles mentionnées ci-dessus, vous pouvez obtenir la valeur en chiffres modernes correspondant au chiffre romain donné.</p>
              <h4 style={{textDecoration:"underline"}}>Exemples de Conversion de chiffres romain</h4>
              <ul>
                <li>Conversion de III en chiffres modernes : 1 + 1 + 1 = 3.</li>
                <li>Conversion de IV en chiffres modernes : 5 - 1 = 4.</li>
                <li>Conversion de IX en chiffres modernes : 10 - 1 = 9.</li>
                <li>Conversion de XL en chiffres modernes : 50 - 10 = 40.</li>
                <li>Conversion de XC en chiffres modernes : 100 - 10 = 90.</li>
              </ul>
              <h3 style={{textDecoration:"underline"}} >Applications Pratiques des chiffres Romain</h3>
              <p>La connaissance de la conversion des chiffres romains en chiffres modernes est utile dans divers contextes tels que l'interprétation de symboles sur des monuments historiques, la compréhension de dates historiques, et dans certaines applications mathématiques et linguistiques spécifiques.</p>
              <p>En comprenant les règles de conversion et en pratiquant avec des exemples, vous pouvez maîtriser l'art de la conversion des chiffres romains en chiffres modernes et apprécier l'importance culturelle et historique de ce système de numération ancien.
              </p>
              </div>
            </Row>
            <Row>
              <div><br />
              <h3 style={{textAlign:"center", fontSize:"1em"}}>
                <i>Continuez la lecture à propos des chiffres Romain et leur histoire fascinante</i></h3>
              <br />
              <Col xs lg="12">
                <LinkContainer
                  to="/histoire-des-chiffres-romain.html"
                  style={{ textAlign: "center" }}
                >
                  <Nav.Link to="/histoire-des-chiffres-romain.html">
                    <Button variant="success" style={{ width: "80%" }}>
                      Histoire Fascinante des Chiffres Romains
                    </Button>
                  </Nav.Link>
                </LinkContainer>

                <LinkContainer
                  to="/du-charme-antique-a-la-précision-moderne.html"
                  style={{ textAlign: "center" }}
                >
                  <Nav.Link to="/du-charme-antique-a-la-précision-moderne.html">
                    <Button variant="success" style={{ width: "80%" }}>
                      Du charme antique à la précision moderne
                    </Button>
                  </Nav.Link>
                </LinkContainer>
              </Col>
              </div>
            </Row>
            <br /><hr />
            <Row style={{ padding: '10px' }}>
              <h3 style={{
                paddingLeft: "10px",
                letterSpacing: "3px",
                color: "#555555",
                borderBottom: "1px #555555 solid",
                fontWeight: "bolder",
                borderLeft: "10px solid #555555",
                textAlign: "left"
              }}
              >
                Pourquoi Utiliser Notre Convertisseur de chiffre Romain?</h3>
            </Row>
            <Row>
              <Card className="col-lg-4 d-flex align-items-stretch">
                <Card.Body>
                  <Card.Title>Rapidité</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">Conversion instantanée</Card.Subtitle>
                  <Card.Text>
                    Obtenez des résultats instantanés sans attendre. Notre outil est conçu pour vous faire gagner du temps.
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card className="col-lg-4 d-flex align-items-stretch">
                <Card.Body>
                  <Card.Title>Précision garantie</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">Précis au centième près</Card.Subtitle>
                  <Card.Text>
                    Notre convertisseur garantit des conversions précises et fiables entre les systèmes de numération romaine et arabe.
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card className="col-lg-4 d-flex align-items-stretch">
                <Card.Body>
                  <Card.Title>Flexibilité</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">Adaptable à tout context de notation</Card.Subtitle>
                  <Card.Text>
                    Utilisez notre convertisseur sur n'importe quel appareil connecté à Internet, que ce soit un ordinateur de bureau, un smartphone ou une tablette.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Row>
            <br />
            <Row>
              <Card className="col-lg-4 d-flex align-items-stretch">
                <Card.Body>
                  <Card.Title>Gratuit</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">Utilisable sans contrainte</Card.Subtitle>
                  <Card.Text>
                    Les services et le convertisseur développés par chiffres-romain.com sont gratuit et libre d'utilisation.
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card className="col-lg-4 d-flex align-items-stretch">
                <Card.Body>
                  <Card.Title>Convivialité</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">Facile d'utilisation</Card.Subtitle>
                  <Card.Text>
                    Notre interface conviviale rend la conversion simple et accessible à tous, sans nécessiter de connaissances préalables.
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card className="col-lg-4 d-flex align-items-stretch">
                <Card.Body>
                  <Card.Title>Protection des données</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">Respect de la vie privée</Card.Subtitle>
                  <Card.Text>
                    Notre site web garantit la sécurité de toutes les informations que vous entrez dans le convertisseur. Aucune donnée personnelle n'est stockée, assurant ainsi votre confidentialité et votre tranquillité d'esprit.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Row> <br />
          </Container>
        </Jumbotron>
      </div>
    );
  }
}

export default About;
