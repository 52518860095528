import React, { useState } from 'react';
import { Row, Col, Card, Container, Button } from "react-bootstrap";
import { PiNumberCircleOneFill, PiWarningDiamondBold } from "react-icons/pi";
import Jumbotron from "react-bootstrap/Jumbotron";
import SatisfactionForm from "./Satisfaction";
import { Helmet } from "react-helmet";
import BG from "./bg.jpeg";
import { Nav } from "react-bootstrap"; // NavDropdown
import { LinkContainer } from "react-router-bootstrap";


function test(roman) {
  let result = 0;
  const romanNumerals = {
    I: 1,
    V: 5,
    X: 10,
    L: 50,
    C: 100,
    D: 500,
    M: 1000,
  };
  for (let i = 0; i < roman.length; i++) {
    const currentNumeral = romanNumerals[roman[i]];
    const nextNumeral = romanNumerals[roman[i + 1]];
    if (currentNumeral < nextNumeral) {
      result -= currentNumeral;
    } else {
      result += currentNumeral;
    }
  }
  if (result) {
    return {
      result: result,
    }
  } else {
    return {
      result: "Votre résultat ici"
    }
  }


}

function ConvertRomainToModern() {
  // État local pour gérer les valeurs des inputs
  const [romanValue, setInput] = useState('');

  // Gestionnaire d'événements pour mettre à jour la valeur du premier input
  const handleInputChange = (event) => {
    setInput(event.target.value.toUpperCase().replace(/[^IVXLCDM]/g, ''));
    test(romanValue);
  };


  return (

    <Jumbotron
      style={{
        color: "#efefef",
        fontFamily: "arial",
        backgroundImage: `url(${BG}), linear-gradient(45deg,#aaa 50.9%,#000 60%)`,
        filter: "grayscale(80%)",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundBlendMode: "multiply",
        backgroundRepeat: 'no-repeat',
        marginBottom: "0px"
      }} fluid
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>chiffres-romain.com - convertir des chiffres Romain en chiffre standard moderne Arabe</title>
        <meta name="description" content="Convertisseur en ligne de chiffres romain en chiffres moderne" />
      </Helmet>
      <Container >
        <Container>
          <Row>
            <Col xs lg="12" style={{ padding: "20px", textAlign: "center", color: "#efefef" }}>
              <Row>
                <h1 style={{
                  paddingLeft: "10px",
                  letterSpacing: "5px",
                  color: "#efefef",
                  fontWeight: "bolder",
                  borderLeft: "10px solid #555555",
                }}
                > <span style={{ letterSpacing: "3px", fontSize: "10px", textTransform: "none" }}>
                    &nbsp;chiffres-romain.com &nbsp;
                  </span>
                  Convertisseur de Chiffres Romain Antique en chiffres Modernes Standards Arabe en ligne</h1>
                <p style={{ textAlign: 'justify', padding: '10px' }}>
                  La numération romaine est un système ancien utilisé à l'origine par l'Empire romain.
                  Elle est basée sur un ensemble de symboles qui représentent des valeurs spécifiques.
                  Notre convertisseur vous permet de convertir instantanément <b>un chiffre romain en chiffre standard moderne</b>.
                  Il vous suffit d'entrer le chiffre romain dans la barre de conversion, et notre outil affichera immédiatement la valeur correspondante en chiffre standard.
                </p>
                
              </Row>
            </Col>          

          </Row>
          

        </Container>
        <Row>
          <Col xs={12} lg={6}>

            <p style={{ padding: "15px", background: "transparent", color: "#efefef" , textAlign:"center"}}>
                      <PiWarningDiamondBold size={40} style={{ color: "orangered" }} /><br/>
                      <b>Seuls les symboles suivants sont pris en comptes</b><br /><hr/>
                      I = 1 <br />
                      V = 5<br />
                      X = 10<br />
                      L = 50<br />
                      C = 100<br />
                      D = 500<br />
                      M = 1000<br />
                    </p>
  
          </Col>
          <Col xs={12} lg={6}>
            <Card style={{ background: "transparent" , border:"none"}}>
              <Card.Body style={{border:"none" }}>
                <Card.Title style={{ color: "#efefef", textAlign: "center" }}><br />Convertir un chiffre romain en nombre modernes Arabe</Card.Title>

                <form data-netlify="true">
                  <div className="container-form">
                    <div className="did-floating-label-content">
                      <input
                        placeholder=" "
                        title="Votre chiffre romain"
                        id="romanValue"
                        type="text"
                        className="form-control-lg did-floating-input"
                        value={romanValue}
                        name="romanValue"
                        onChange={handleInputChange}
                        autocomplete="off"
                        style={{ textAlign: "center" }}
                      />
                      <label style={{ color: 'orangered', "borderRadius": "30px" }} className="did-floating-label"><PiNumberCircleOneFill style={{ color: 'orangered' }} />
                        Chiffre romain à convertir ?</label>

                    </div>
                    <a href="//shulugoo.net/4/6461561" target="_blank" rel="noopener noreferrer">
                    <p id="res" 
                    style={{ fontWeight: "bolder", textAlign: "center", color: "green", "boxShadow": "1px 2px 2px 2px", background: '#fff', border: "1px solid silver", borderRadius: '10px', padding: '10px' }}>
                      <span style={{}}>
                      {test(romanValue).result}</span>
                      </p></a>                   
                  </div>
                </form>
                <LinkContainer
                    to="/convertisseur-chiffre-moderne-vers-chiffres-Romain-en-ligne.html"
                    style={{ textAlign: "center" }}
                  >
                    <Nav.Link to="/convertisseur-chiffre-moderne-vers-chiffres-Romain-en-ligne.html">
                      <Button variant="success" style={{ width: "80%" }}>
                        Convertir des chiffres modernes en chiffres Romain
                      </Button>
                    </Nav.Link>
                  </LinkContainer>

              </Card.Body>
            </Card>

          </Col>
        </Row><br />
        <Container style={{ background: "#fff", textAlign: "center" }}> <br /> <SatisfactionForm /></Container>

      </Container>
    </Jumbotron >

  );
}

export default ConvertRomainToModern;
