import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./App.css";
import TopNavBar from "./Components/TopNavBar";
import Legal from "./Components/Legal";
import CookieConsent from "./Components/CookieConsent";
import Footer from "./Components/Footer";
import Histoire from "./Components/Histoire";
import ScrollToTop from "./Components/ScrollToTop";
import NotFound from "./Components/404";
import { AnimatedSwitch } from 'react-router-transition';
import About from "./Components/About";
import Alternative from "./Components/Alternative";
import ConvertModernToRomain from "./Components/ConvertModernToRomain";
import ConvertRomainToModern from "./Components/ConvertRomainToModern";


const App = () => {

  return (

    <Router>
      <CookieConsent />
      <TopNavBar />
      <ScrollToTop />
      <AnimatedSwitch
        atEnter={{ offset: -100 }}
        atLeave={{ offset: 0 }}
        atActive={{ offset: 0 }}
        mapStyles={(styles) => ({
          transform: `translateX(${styles.offset}%)`,
        })}
        className="switch-wrapper">
        <Route exact path="/" render={(props) => <About />}>
          <About />
        </Route>
        <Route path="/histoire-des-chiffres-romain.html" component={() => <Histoire />}></Route>
        <Route path="/convertisseur-chiffre-moderne-vers-chiffres-Romain-en-ligne.html" component={() => <ConvertModernToRomain />}>
          <ConvertModernToRomain />
        </Route>
        {new Array(5001).fill().map((page, i) => {
          return (
            <Route key={i} path={`/convertir-${i}-en-chiffre-romain.html`}>
              <ConvertModernToRomain chiffre={i} />
            </Route>
          );
        })}
        <Route path="/convertisseur-chiffre-moderne-vers-chiffres-Romain-en-ligne.html" component={() => <ConvertModernToRomain />}>
          <ConvertModernToRomain />
        </Route>
        <Route path="/convertir-en-chiffre-romain.html" component={() => <ConvertModernToRomain />}>
          <ConvertModernToRomain />
        </Route>
        <Route path="/convertisseur-chiffres-Romain-vers-chiffre-moderne-en-ligne.html" component={() => <ConvertRomainToModern />}>
          <ConvertRomainToModern />
        </Route>
        <Route path="/du-charme-antique-a-la-précision-moderne.html" component={() => <Alternative />}>
          <Alternative />
        </Route>
        <Route path="/Legal.html">
          <Legal />
        </Route>

        <Route component={NotFound} />
      </AnimatedSwitch>
      <Footer />
    </Router >
  );

}

export default App;
