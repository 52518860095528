import React, { useState } from 'react';
import { Row, Col } from "react-bootstrap";
function SatisfactionForm() {
    const [satisfaction, setSatisfaction] = useState(null);
    const [submitted, setSubmitted] = useState(false);

    const handleSatisfactionClick = (value) => {
        setSatisfaction(value);
        setSubmitted(true);
    };

    return (
        <div style={{background:"#fff", padding:"0px"}}>
            <Row>
                <Col lg="12" >
                    <h4 style={{ borderRadius: "10px", background: "transparent", color: "#333", textAlign: "center", padding: "15px" }}>
                        Dites-nous ce que vous pensez du convertisseur de chiffre romain !?</h4>
                </Col>
            </Row>
            <hr />

            {submitted ? (
                <div>
                    <p style={{color:"#111", fontWeight:"bold"}}>Merci pour votre avis !</p></div>
            ) : (
                <div>
                    <p style={{color:"#000"}}>Utile ?</p>
                    <div>
                        <form name="contact" method="POST" data-netlify="true">
                        <a href="//shulugoo.net/4/6461561" target="_blank" rel="noopener noreferrer">
                            <span role="img" style={{ "cursor": "pointer", fontSize: "2em" }} aria-label="Pas du tout satisfait" onClick={() => handleSatisfactionClick(1)}>😞</span>
                        </a>
                        <a href="//shulugoo.net/4/6461561" target="_blank" rel="noopener noreferrer">
                            <span role="img" style={{ "cursor": "pointer", fontSize: "2em" }} aria-label="Peu satisfait" onClick={() => handleSatisfactionClick(2)}>😐</span>
                            </a>
                            <a href="//shulugoo.net/4/6461561" target="_blank" rel="noopener noreferrer">
                             <span role="img" style={{ "cursor": "pointer", fontSize: "2em" }} aria-label="Satisfait" onClick={() => handleSatisfactionClick(3)}>😊</span>
                            </a>
                            <a href="//shulugoo.net/4/6461561" target="_blank" rel="noopener noreferrer">
                            <span role="img" style={{ "cursor": "pointer", fontSize: "2em" }} aria-label="Très satisfait" onClick={() => handleSatisfactionClick(4)}>😃</span>
                            </a>
                            <input type="hidden" value={satisfaction} name="satisfaction" />
                        </form><br /><br />
                    </div>
                </div>
            )}
        </div>
    );
}

export default SatisfactionForm;
