import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
const NotFoud = () => {
  return (
    <Container style={{ textAlign: "center", marginTop: "150px", color: "#515151" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>404 - chiffres-romain.com  - page non trouvée </title>
        <meta name="description" content="Page non trouvée" />
      </Helmet>
      <hr />
      <h1 style={{ fontSize: "4em" }}>Oulala !!  Erreur 404</h1>
      <h2>Vous vous êtes perdu sur chiffres-romain.com ???</h2> <br /><br />
      <h5 style={{ textAlign: "center", }}>Désolé la page demandée n'existe pas encore </h5>

      <br /><br /><hr />
    </Container>
  );
}

export default NotFoud;