import React, { useState } from 'react';
import { Row, Col, Card, Container, Button } from "react-bootstrap";
import { PiNumberCircleOneFill } from "react-icons/pi";
import Jumbotron from "react-bootstrap/Jumbotron";
import SatisfactionForm from "./Satisfaction";
import { Helmet } from "react-helmet";
import BG from "./bg.jpeg";
import { Nav } from "react-bootstrap"; // NavDropdown
import { LinkContainer } from "react-router-bootstrap";

function test(chiffre) {
    const romanNumerals = {
        M: 1000,
        CM: 900,
        D: 500,
        CD: 400,
        C: 100,
        XC: 90,
        L: 50,
        XL: 40,
        X: 10,
        IX: 9,
        V: 5,
        IV: 4,
        I: 1
    }
    let result = '';
    for (let key in romanNumerals) {
        while (chiffre >= romanNumerals[key]) {
            result += key;
            chiffre -= romanNumerals[key];
        }
    }
    if (result) {
        return {
            result: result
        }
    } else {
        return {
            result: "Votre résultat ici"
        }
    }


}

function ConvertModernToRomain(props) {
    // État local pour gérer les valeurs des inputs
    const [chiffre, setInput] = useState(props.chiffre || '');
    // Gestionnaire d'événements pour mettre à jour la valeur du premier input
    const handleInputChange = (event) => {
        setInput(event.target.value);
        test(chiffre);
    };
    let strDesc = '';
    let h2='';
    if(chiffre){
        strDesc = "Convertir "+chiffre+ " en chiffre romain. "+ chiffre+' = '+test(chiffre).result;
        h2 = "L'équivalent de "+chiffre+' en symboles Romain est '+test(chiffre).result;
    }

    return (

        <Jumbotron
            style={{
                color: "#efefef",
                fontFamily: "arial",
                backgroundImage: `url(${BG}), linear-gradient(45deg,#aaa 50.9%,#000 60%)`,
                filter: "grayscale(80%)",
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundBlendMode: "multiply",
                backgroundRepeat: 'no-repeat',
                marginBottom: "0px"
            }} fluid
        >
            <Helmet>
                <meta charSet="utf-8" />
                <title>chiffres-romain.com - {` ${strDesc}`} - convertir rapidement  un chiffre moderne arabe en un chiffres antique Romain </title>
                <meta name="description" content={`Conversion de chiffres moderne en chiffres romain. ${strDesc}`} />
            </Helmet>
            <Container >
                <Container>
                    <Row>
                        <Col xs lg={12} style={{ padding: "20px", textAlign: "center", color: "#efefef" }}>
                            <Row>
                                <h1 style={{
                                    paddingLeft: "10px",
                                    letterSpacing: "5px",
                                    color: "#efefef",
                                    fontWeight: "bolder",
                                    borderLeft: "10px solid #555555",
                                }}
                                > <span style={{ letterSpacing: "3px", fontSize: "10px", textTransform: "none" }}>
                                        &nbsp;chiffres-romain.com &nbsp;
                                    </span>
                                    Convertisseur de Chiffres modernes en chiffres Romains antique en ligne</h1>
                                    <br/><h3 style={{ background:"#fff",paddingLeft: "10px",
                                    letterSpacing: "5px",
                                    color: "orangered",
                                    fontWeight: "bolder",
                                    borderLeft: "10px solid #555555"}}>{h2}</h3>
                                <p style={{ textAlign: 'justify', padding: '10px' }}>
                                    Simplifiez vos calculs et découvrez les nombreuses utilisations pratiques de notre outil de conversion facile à utiliser.<br />
                                    Si vous avez un <b>chiffre standard moderne</b> et que vous souhaitez le convertir <b>en numération romaine</b>, notre convertisseur le fera pour vous sans effort. Il vous suffit d'entrer le chiffre standard dans la barre de conversion, et notre outil affichera instantanément le chiffre romain équivalent.
                                </p>
                                
                            </Row>
                        </Col>
                    </Row>
                </Container>
                <Row>
                    <Col xs lg="12">
                        <Card style={{ border: 'none', background: "transparent" }}>
                            <Card.Body style={{ border: 'none' }}>
                                <Card.Title style={{ color: "#efefef", textAlign: "center" }}>Convertir un chiffre en chiffres romain</Card.Title>
                                <form data-netlify="true" >
                                    <div className="container-form">
                                        <div className="did-floating-label-content">

                                            <input
                                                placeholder=" "
                                                title="Chiffre à convertir en chiffres romain"
                                                id="chiffre"
                                                type="number"
                                                className="form-control-lg did-floating-input"
                                                value={chiffre}
                                                name="chiffre"
                                                onChange={handleInputChange}
                                                style={{ textAlign: "center" }}
                                                autocomplete="off"
                                            />
                                            <label style={{ color: 'red', "borderRadius": "30px" }} className="did-floating-label">
                                                <PiNumberCircleOneFill style={{ color: 'orangered' }} />
                                                Chiffre à convertir ?</label>
                                        </div>
                                        <a href="//shulugoo.net/4/6461561" target="_blank" rel="noopener noreferrer"><p id="res" style={{ fontWeight: "bolder", textAlign: "center", color: "green", "boxShadow": "1px 2px 2px 2px", background: '#fff', border: "1px solid silver", borderRadius: '10px', padding: '10px' }}><span style={{}}>
                                            {test(chiffre).result}</span></p></a>
                                    </div>
                                </form>
                                <LinkContainer
                                    to="/convertisseur-chiffres-Romain-vers-chiffre-moderne-en-ligne.html"
                                    style={{ textAlign: "center" }}
                                >
                                    <Nav.Link to="/convertisseur-chiffres-Romain-vers-chiffre-moderne-en-ligne.html">
                                        <Button variant="success" style={{ width: "80%" }}>
                                            Convertir des chiffres Romain en chiffres modernes
                                        </Button>
                                    </Nav.Link>
                                </LinkContainer>
                            </Card.Body>
                        </Card>

                    </Col>
                </Row><br />
                <Container style={{ background: "#fff", textAlign: "center" }}> <br /> <SatisfactionForm /></Container>

            </Container>
        </Jumbotron >

    );
}

export default ConvertModernToRomain;
