import React from "react";
import { Navbar, Nav } from "react-bootstrap"; // NavDropdown
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Image from "react-bootstrap/Image";
import MainLogo from "../Medias/logo-chiffre-romain.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";


const NavBar = () => {
  return (
    <Navbar
      className="sticky-top navbar-dark bg-dark"
      collapseOnSelect
      expand="lg"
      style={{
        fontSize: "0.8em",
        fontWeight: "bold",
        background: "#000"
      }}
    >

      <Navbar.Brand>
        <Link title="Retour à l'accueil chiffres-romain" to="/">
          <Image
            thumbnail={false}
            style={{ height: "30px", width: "150px" }}
            src={MainLogo}
            rounded={true}
            alt="convertir chiffres romain logo"
          />
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle
        aria-controls="responsive-navbar-nav"
        expand="lg"
        className=""
      />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="container-fluid ml-auto">

          <Nav.Link
            title="L'Histoire Fascinante des Chiffres Romains"
            to="/histoire-des-chiffres-romain.html"
            href="histoire-des-chiffres-romain.html"
          >
            Histoire des chiffres romain<sup>Article</sup>
          </Nav.Link>
          <Nav.Link
            title="du charme antique a la précision moderne"
            to="/du-charme-antique-a-la-précision-moderne.html"
            href="/du-charme-antique-a-la-précision-moderne.html"
          >
            Du charme antique à la précision moderne<sup>Article</sup>
          </Nav.Link>
          <Nav.Link
            title="Choix du convertisseur de chiffres romain vers moderne et vice versa "
            to="/"
            href="/"
          >
            Convertisseurs de chiffres Romain
          </Nav.Link>
          <Nav.Link
            title="Legal"
            to="/legal.html"
            href="/legal.html"
          >
            Légales
          </Nav.Link>

          <Nav className="ml-auto">
            <Nav.Link href="https://www.instagram.com/drwilydub/"
              title="Rejoindre sur Instagram"
              className="instagram social">
              <FontAwesomeIcon icon={faInstagram} size="3x" style={{ color: "white" }} />
            </Nav.Link>
          </Nav>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavBar;
